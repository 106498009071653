const routes = {
  default: "/",
  optimalPicture: "/optimal-picture",
  termsAndConditions: "/terms-and-conditions",
  privacyPolicy: "/privacy-policy",
  leaderboards: "/leaderboards",
};

export const homepageAnchorIdentifiers = {
  home: "home",
  features: "features",
  download: "download",
  price: "price",
  feedback: "feedback",
  team: "team",
  leaderboards: "leaderboards",
};

export default routes;
