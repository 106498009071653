const settings = {
  facebook: "https://www.facebook.com/surehandshooting",
  adminUrl: "/admin",
  email: "support@surehand.digital",
  privacyPolicy: "https://www.trigama.eu/en/datainfo/",
  apiVersion: "/v1/",
  recaptchaKey: "6LfIs3kaAAAAAC91HCrxoyrrTEsWgXC-WcoFBdLA",
};

export default settings;
