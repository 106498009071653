const min = {
  xs: 0,
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
};

const max = {
  xs: "575px",
  sm: "767px",
  lg: "991px",
  xl: "199px",
};

export default {
  min,
  max,
};
