import { css, Global, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import styleVariables from "./helpers/styleVariables";

export const globalStyles = (
  <Global
    styles={css`
      html,
      body {
        font-family: "Roboto", sans-serif;
        color: ${styleVariables.colors.grey};
        position: relative;
      }
      .z-1 {
        z-index: 1;
      }

      .z-2 {
        z-index: 2;
      }

      .z-3 {
        z-index: 3;
      }
      @media (min-width: 992px) {
        .o-lg-1 {
          order: 1;
        }
        .o-lg-2 {
          order: 2;
        }
      }
    `}
  />
);
