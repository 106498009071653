import React from "react";
import styled from "@emotion/styled";

interface IconProps {
  imgSrc: string;
  imgAlt?: string;
  small?: boolean;
  className?: string;
  handleClick?: () => void;
}

type IconStylesProps = Pick<IconProps, "small">;

const Root = styled.img<IconStylesProps>`
  width: ${(props) => (props.small ? 16 : 32)}px;
  height: ${(props) => (props.small ? 16 : 32)}px;
`;

const Avatar: React.FC<IconProps> = ({
  imgSrc,
  imgAlt,
  small,
  className,
  handleClick,
}) => {
  return (
    <Root
      src={imgSrc}
      alt={imgAlt || ""}
      small={small}
      className={className}
      onClick={handleClick}
    />
  );
};

export default Avatar;
