import React from "react";
import styled from "@emotion/styled";
import styleVariables from "../../helpers/styleVariables";
import breakpoints from "../../helpers/breakpoints";
import { useRouter } from "next/router";
import settings from "../../settings";
import routes, { homepageAnchorIdentifiers } from "../../routes";
import { useTranslation } from "next-i18next";
import Icon from "../icon/icon";

interface HeaderStylesProps {
  minimized: boolean;
}

const Root = styled.div<HeaderStylesProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  padding: ${(props) => (props.minimized ? "10px 0" : "30px 0")};
    background: ${(props) =>
      props.minimized ? styleVariables.colors.white : "transparent"};
    box-shadow: ${(props) =>
      props.minimized ? styleVariables.boxShadow.regular : "none"};
  transition: all .2s ease-in-out;
  transition-property: padding;
  z-index: 100;

  @media(max-width: ${breakpoints.max.sm} {
    padding: 10px 0;
    background: ${styleVariables.colors.white};
    box-shadow: ${styleVariables.boxShadow.regular};
  }

`;

const HeaderItem = styled.a`
  color: ${styleVariables.colors.blueRegular};
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px;
  @media (min-width: 1500px) {
    padding: 12px;
  }
  cursor: pointer;

  &:first-of-type {
    margin-left: -4px;
    @media (min-width: 1500px) {
      margin-left: -12px;
    }
  }

  &:hover,
  &:active {
    text-decoration: none;
    color: ${styleVariables.colors.blueDark};
  }
`;

const SocialIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const ActiveBlock = styled.div`
  background: ${styleVariables.colors.blueRegular};
  height: 1px;
  position: absolute;
  bottom: -6px;
  transition: 0.3s ease-in-out;
  opacity: 1;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-duration: 1s;
`;

const OffCanvasMenu = styled.div`
  background: ${styleVariables.colors.blueRegular};
  color: #fff;
  width: 80%;
  position: fixed;
  left: 20%;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  // transform: translateX(100%);
  transition: 0.3s ease-in-out;
  z-index: 1000;
`;

const OffCanvasMenuItem = styled.a`
  display: block;
  color: #fff;
  padding: 0.625rem 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  &:hover {
    color: #fff;
  }
`;

const Header: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const [offCanvasmenuOpen, setOffCanvasMenuOpen] =
    React.useState<boolean>(false);

  const activeBlockRef = React.useRef<HTMLDivElement | null>(null);
  const navRef = React.useRef<HTMLElement | null>(null);

  const [headerMinimized, setHeaderMinimized] = React.useState<boolean>(false);

  const controlDirection = () => {
    setHeaderMinimized(window.scrollY > 10);
  };

  interface MenuItem {
    route: string;
    anchorId: string;
    text: string;
  }

  const menuItems: MenuItem[] = [
    {
      route: routes.default,
      anchorId: homepageAnchorIdentifiers.features,
      text: t("menuItem.features"),
    },
    {
      route: routes.default,
      anchorId: homepageAnchorIdentifiers.download,
      text: t("menuItem.download"),
    },
    {
      route: routes.default,
      anchorId: homepageAnchorIdentifiers.price,
      text: t("menuItem.price"),
    },
    {
      route: routes.default,
      anchorId: homepageAnchorIdentifiers.feedback,
      text: t("menuItem.feedback"),
    },
    {
      route: routes.default,
      anchorId: homepageAnchorIdentifiers.team,
      text: t("menuItem.team"),
    },
    {
      route: `${routes.leaderboards}?discipline=10m_air_pistol`,
      anchorId: homepageAnchorIdentifiers.leaderboards,
      text: t("menuItem.leaderboards"),
    },
  ];

  const homeMenuItem = {
    route: routes.default,
    anchorId: homepageAnchorIdentifiers.home,
    text: t("menuItem.home"),
  };
  menuItems.unshift(homeMenuItem);

  const handleMenuActiveBlock = () => {
    let activeAnchorId = "#";
    menuItems.forEach((menuItem) => {
      const testedElem = document.getElementById(menuItem.anchorId);
      if (testedElem && window.scrollY > testedElem.offsetTop - 5) {
        activeAnchorId = menuItem.anchorId;
      }
    });

    if (activeBlockRef.current) {
      const element = document.querySelector<HTMLElement>(
        `[data-item-id="${activeAnchorId}"] span`
      );
      if (element) {
        activeBlockRef.current.style.width = element.offsetWidth + "px";
        activeBlockRef.current.style.left = element.offsetLeft + "px";
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", controlDirection);
    window.addEventListener("scroll", handleMenuActiveBlock);
    window.addEventListener("resize", handleMenuActiveBlock);
    handleMenuActiveBlock();
    document.fonts.ready.then(function () {
      handleMenuActiveBlock();
    });
    return () => {
      window.removeEventListener("scroll", controlDirection);
      window.removeEventListener("scroll", handleMenuActiveBlock);
      window.removeEventListener("resize", handleMenuActiveBlock);
    };
  }, []);

  React.useEffect(() => {
    handleMenuActiveBlock();
  }, [router]);

  const handleMenuItemClick = (menuItem: MenuItem) => {
    const isHome = router.pathname === "/";
    if (isHome && menuItem.route === routes.default) {
      document
        .getElementById(menuItem.anchorId)
        ?.scrollIntoView({ behavior: "smooth" });
    } else {
      router.push(
        `${menuItem.route}${
          menuItem.anchorId && menuItem.route === routes.default
            ? `#${menuItem.anchorId}`
            : ""
        }`
      );
    }
  };

  const handleLogoClick = (e: any) => {
    e.preventDefault();
    handleMenuItemClick(homeMenuItem);
  };

  return (
    <header>
      <Root id="js-header" minimized={headerMinimized}>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-8 col-xl-2 offset-2 offset-xl-0 d-flex justify-content-center">
              <a href="/" onClick={handleLogoClick}>
                <img
                  src="/images/logo.svg"
                  alt="SureHand"
                  className="img-fluid"
                />
              </a>
            </div>

            <div className="col-2 col-xl-10 d-flex justify-content-end">
              <nav className="ml-3 mr-3 d-none d-xl-block" ref={navRef}>
                {menuItems.map((menuItem) => (
                  <HeaderItem
                    href={`${menuItem.route}#${menuItem.anchorId}`}
                    key={menuItem.text}
                    data-item-id={menuItem.anchorId}
                    onClick={(e) => {
                      e.preventDefault();
                      handleMenuItemClick(menuItem);
                    }}
                  >
                    <span>{menuItem.text}</span>
                  </HeaderItem>
                ))}
              </nav>
              <ActiveBlock ref={activeBlockRef} />
              <Icon
                imgSrc="/images/icons/menu.svg"
                imgAlt="Menu"
                className="d-xl-none"
                handleClick={() => setOffCanvasMenuOpen(true)}
              />
              <a
                href={settings.adminUrl}
                target="_blank"
                rel="noopener"
                className="d-none d-xl-inline-flex mr-3"
              >
                <SocialIcon src="/images/settings.svg" alt="Admin" />
              </a>
              <a
                href={settings.facebook}
                target="_blank"
                rel="noopener"
                className="d-none d-xl-inline-flex mr-3"
              >
                <SocialIcon src="/images/facebook.svg" alt="Facebook" />
              </a>
              <a
                href={`mailto:${settings.email}`}
                className="d-none d-xl-inline-flex mr-3"
              >
                <SocialIcon src="/images/icons/email.svg" alt="Email" />
              </a>
            </div>
          </div>
        </div>
      </Root>
      {offCanvasmenuOpen && (
        <OffCanvasMenu>
          <div className="d-flex justify-content-between py-2 px-3 align-items-center">
            <a href={settings.facebook} target="_blank" rel="noopener">
              <Icon imgSrc="/images/facebook-white.svg" imgAlt="Facebook" />
            </a>
            <a
              href={settings.adminUrl}
              target="_blank"
              rel="noopener"
              style={{ marginLeft: "8px", marginRight: "auto" }}
            >
              <Icon imgSrc="/images/settings-white.svg" imgAlt="Admin" />
            </a>
            <Icon
              imgSrc="/images/icons/close.svg"
              imgAlt="Close"
              handleClick={() => setOffCanvasMenuOpen(false)}
            />
          </div>
          <nav>
            {menuItems.map((menuItem) => (
              <OffCanvasMenuItem
                href={`${menuItem.route}#${menuItem.anchorId}`}
                key={menuItem.text}
                data-item-id={menuItem.anchorId}
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuItemClick(menuItem);
                  setOffCanvasMenuOpen(false);
                }}
              >
                <span>{menuItem.text}</span>
              </OffCanvasMenuItem>
            ))}
          </nav>
        </OffCanvasMenu>
      )}
    </header>
  );
};

export default Header;
