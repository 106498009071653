import React from "react";
import styled from "@emotion/styled";
import styleVariables from "../../helpers/styleVariables";
import { useTranslation } from "next-i18next";

export enum ButtonVariant {
  primary = "primary",
  secondary = "secondary",
  ternary = "ternary",
}

interface ButtonProps {
  variant?: ButtonVariant;
  disabled?: boolean;
  className?: string;
  handleClick?: () => void;
  href?: string;
  type?: string;
}

const Root = styled.a<ButtonProps>`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  padding: 11px 11px;
  border-width: 2px;
  border-style: solid;
  display: block;
  width: 100%;
  background: ${(props) =>
    props.variant === ButtonVariant.primary
      ? styleVariables.colors.blueRegular
      : props.variant === ButtonVariant.secondary
      ? styleVariables.colors.greenDark
      : "transparent"};
  border-color: ${(props) =>
    props.variant === ButtonVariant.primary ||
    props.variant === ButtonVariant.ternary
      ? styleVariables.colors.blueRegular
      : styleVariables.colors.greenDark};
  color: ${(props) =>
    props.variant === ButtonVariant.ternary
      ? styleVariables.colors.blueRegular
      : styleVariables.colors.white};
  border-radius: 4px;
  &:hover {
    background: ${(props) =>
      props.variant === ButtonVariant.primary ||
      props.variant === ButtonVariant.ternary
        ? styleVariables.colors.blueDark
        : styleVariables.colors.greenRegular};
    color: ${styleVariables.colors.white};
    border-color: ${(props) =>
      props.variant === ButtonVariant.primary ||
      props.variant === ButtonVariant.ternary
        ? styleVariables.colors.blueDark
        : styleVariables.colors.greenRegular};
  }

  &:hover,
  &:active {
    text-decoration: none;
  }

  svg {
    position: relative;
    top: -1px;
  }
`;

const Button: React.FC<ButtonProps> = ({
  className,
  variant,
  disabled,
  children,
  handleClick,
  href,
  type,
}) => {
  return (
    <Root
      className={className}
      variant={variant}
      disabled={disabled}
      onClick={(e) => {
        if (handleClick) {
          e.preventDefault();
        }
        handleClick ? handleClick() : undefined;
      }}
      type={type}
      href={href}
      as={type === "submit" || !href ? "button" : "a"}
    >
      {children}
    </Root>
  );
};

export default Button;
