import React from "react";
import styled from "@emotion/styled";
import styleVariables from "../../../helpers/styleVariables";

export enum ParagraphSize {
  large = "large",
  small = "small",
}

export enum ParagraphType {
  success = "success",
  error = "error",
}
interface ParagraphProps {
  size?: ParagraphSize;
  type?: ParagraphType;
  className?: string;
}

type ParagraphStylesProps = Pick<ParagraphProps, "size" | "type">;

const Root = styled.p<ParagraphStylesProps>`
  font-size: ${(props) => {
    if (props.size === ParagraphSize.large) {
      return styleVariables.fontSize.paragraphLarge;
    } else if (props.size === ParagraphSize.small) {
      return styleVariables.fontSize.paragraphSmall;
    } else {
      return styleVariables.fontSize.paragraphRegular;
    }
  }};
  line-height: ${(props) => {
    if (props.size === ParagraphSize.large) {
      return 1.55;
    } else if (props.size === ParagraphSize.small) {
      return 1.42;
    } else {
      return 1.5;
    }
  }};
  font-weight: ${(props) =>
    props.type === ParagraphType.success || props.type === ParagraphType.error
      ? "bold"
      : "normal"};
  color: ${(props) => {
    if (props.type === ParagraphType.success) {
      return styleVariables.colors.greenRegular;
    } else if (props.type === ParagraphType.error) {
      return styleVariables.colors.red;
    } else {
      return "unset";
    }
  }};
  a {
    color: inherit;
    text-decoration: underline;

    &:hover, &:focus {
      text-decoration: none;
    }
  }
}};
`;

const Paragraph: React.FC<ParagraphProps> = ({
  className,
  size,
  type,
  children,
}) => {
  return (
    <Root size={size} type={type} className={className}>
      {children}
    </Root>
  );
};

export default Paragraph;
