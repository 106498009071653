import React from "react";
import styled from "@emotion/styled";
import styleVariables from "../../helpers/styleVariables";
import breakpoints from "../../helpers/breakpoints";
import Icon from "../icon/icon";
import Paragraph, { ParagraphSize } from "../typo/paragraph/paragraph";
import routes, { homepageAnchorIdentifiers } from "../../routes";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import settings from "../../settings";

const Root = styled.footer`
  background: ${styleVariables.colors.grey};
  color: ${styleVariables.colors.white};
  padding: 36px 0;
  @media (min-width: ${breakpoints.min.md}) {
    padding: 64px 0;
  }
  a {
    &:hover,
    &:active {
      text-decoration: none;
      color: ${styleVariables.colors.white};
    }
  }
`;

const FooterLogo = styled.img`
  width: 165px;
  margin-bottom: 16px;
`;

const OpacityText = styled.div`
  opacity: 0.92;
`;

const SocialSite = styled.a`
  margin: 0 16px;
  display: inline-block;
  align-items: center;
  font-weight: 500;
  opacity: 0.92;
  img {
    margin-top: -0.25rem;
    margin-right: 0.5rem;
  }
`;

interface FooterLinkStylesProps {
  upperCase?: boolean;
}

const FooterLink = styled.a<FooterLinkStylesProps>`
  text-decoration: underline;
  padding: 8px;
  margin: -8px;
  font-weight: 500;
  cursor: pointer;
  span {
    text-transform: ${(props) => (props.upperCase ? "uppercase" : "none")};
  }
`;

interface Link {
  route: string;
  text: string;
}
interface MenuItem {
  route: string;
  anchorId: string;
  text: string;
}
const Advantages: React.FC = () => {
  const router = useRouter();

  const { t } = useTranslation();

  const menuItems: MenuItem[] = [
    {
      route: routes.default,
      anchorId: homepageAnchorIdentifiers.home,
      text: t("menuItem.home"),
    },
    {
      route: routes.default,
      anchorId: homepageAnchorIdentifiers.features,
      text: t("menuItem.features"),
    },
    {
      route: routes.default,
      anchorId: homepageAnchorIdentifiers.download,
      text: t("menuItem.download"),
    },
    {
      route: routes.default,
      anchorId: homepageAnchorIdentifiers.price,
      text: t("menuItem.price"),
    },
    {
      route: routes.default,
      anchorId: homepageAnchorIdentifiers.feedback,
      text: t("menuItem.feedback"),
    },
    {
      route: routes.default,
      anchorId: homepageAnchorIdentifiers.team,
      text: t("menuItem.team"),
    },
    {
      route: routes.leaderboards,
      anchorId: homepageAnchorIdentifiers.leaderboards,
      text: t("menuItem.leaderboards"),
    },
  ];

  const handleMenuItemClick = (menuItem: MenuItem) => {
    const isHome = router.pathname === "/";
    if (isHome && menuItem.anchorId) {
      document
        .getElementById(menuItem.anchorId)
        ?.scrollIntoView({ behavior: "smooth" });
    } else {
      router.push(
        `${menuItem.route}${
          menuItem.anchorId && menuItem.route === routes.default
            ? `#${menuItem.anchorId}`
            : ""
        }`
      );
    }
  };

  const footerRightColumn: Link[] = [
    {
      text: t("footer.rightColumn.howToMakeOptimalPicture"),
      route: routes.optimalPicture,
    },
    {
      text: t("footer.rightColumn.termsOfUsage"),
      route: routes.termsAndConditions,
    },
    {
      text: t("footer.rightColumn.privacyPolicy"),
      route: routes.privacyPolicy,
    },
  ];

  return (
    <Root>
      <div className="container">
        <div className="row">
          <div className="col-md-7 d-flex flex-column align-items-center align-items-md-start ">
            <FooterLogo src="/images/logo-white.svg" alt="SureHand" />
            <OpacityText>
              <Paragraph
                size={ParagraphSize.small}
                className="mb-5 text-md-left text-center"
              >
                Copyright {new Date().getFullYear()} SUREHAND
                <br className="d-md-none" /> All Rights Reserved
                <br />
                Developed by{" "}
                <a
                  href="https://www.trigama.eu/en/"
                  className="O-footer__link"
                  target="_blank"
                  rel="noopener"
                >
                  Trigama International s.r.o.
                </a>
              </Paragraph>
            </OpacityText>
            <Paragraph
              size={ParagraphSize.small}
              className="mt-auto mb-0 text-md-left text-center"
            >
              <span className="O-footer__opacity">
                You can also find us on social networks
              </span>
              <br className="d-md-none" />{" "}
              <SocialSite
                href="https://www.facebook.com/surehandshooting"
                target="_blank"
                rel="noopener"
                className="mt-3"
              >
                <Icon
                  imgSrc="/images/facebook-white.svg"
                  imgAlt="Facebook icon"
                  small={true}
                />
                <span>Facebook</span>
              </SocialSite>
            </Paragraph>
          </div>
          <div className="col-md-5 mt-4 pt-2">
            <div className="row">
              <div className="col-md-6 text-md-left text-center">
                {menuItems.map((menuItem) => (
                  <Paragraph key={menuItem.anchorId}>
                    <FooterLink
                      upperCase={true}
                      href={`${menuItem.route}#${menuItem.anchorId}`}
                      key={menuItem.text}
                      data-item-id={menuItem.anchorId}
                      onClick={(e) => {
                        e.preventDefault();
                        handleMenuItemClick(menuItem);
                      }}
                    >
                      <span>{menuItem.text}</span>
                    </FooterLink>
                  </Paragraph>
                ))}
              </div>
              <div className="col-md-6 text-md-left text-center">
                {footerRightColumn.map((item) => {
                  return (
                    <Paragraph key={item.route}>
                      <FooterLink
                        href={`/${item.route}`}
                        onClick={(e) => {
                          e.preventDefault();
                          router.push(item.route);
                        }}
                      >
                        {item.text}
                      </FooterLink>
                    </Paragraph>
                  );
                })}
                <Paragraph>
                  <FooterLink
                    href={`mailto:${settings.email}`}
                    className="O-footer__link O-footer__menuItem"
                  >
                    {t("footer.rightColumn.support")}
                  </FooterLink>
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default Advantages;
