import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import React from "react";
import styled from "@emotion/styled";
import styleVariables from "../../helpers/styleVariables";
import Paragraph from "../typo/paragraph/paragraph";
import Button, { ButtonVariant } from "../button/button";
import settings from "../../settings";

const cookieConsentName = "cookie-consent";
const cookieConsentAnalyticsName = "cookie-consent-analytics";
const cookieConsentMarketingName = "cookie-consent-marketing";

const Root = styled.div`
  display: block;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${styleVariables.colors.white};
  color: ${styleVariables.colors.grey};
  z-index: 100;
  border-top: 2px solid ${styleVariables.colors.grey};
`;

const CookieConsent: React.FC<{}> = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isModalOpen, setModalIsOpen] = React.useState<boolean>(false);

  const [isCookiesAnalyticsChecked, setCookiesAnalyticsIsChecked] =
    React.useState<boolean>(true);
  const [isCookiesMarketingChecked, setCookiesMarketingIsChecked] =
    React.useState<boolean>(true);
  const [isFormSubmitted, setFormIsSubmitted] = React.useState<boolean>(false);

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const handleModalOpen = () => {
    setModalIsOpen(true);
  };

  const handleAllowCookies = (allowAll?: boolean) => {
    Cookies.set(cookieConsentName, "1");
    if (allowAll || isCookiesAnalyticsChecked) {
      Cookies.set(cookieConsentAnalyticsName, "1");
    }
    if (allowAll || isCookiesMarketingChecked) {
      Cookies.set(cookieConsentMarketingName, "1");
    }
    handleModalClose();
    setIsOpen(false);
    setFormIsSubmitted(true);
  };

  const handleEscape = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      handleModalClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  });

  React.useEffect(() => {
    if (Cookies.get(cookieConsentName) === undefined) {
      setIsOpen(true);
    }
  }, []);

  const canRenderAnalyticsCookies =
    Cookies.get(cookieConsentAnalyticsName) ||
    (isCookiesAnalyticsChecked && isFormSubmitted);

  const canRenderMarketingCookies =
    Cookies.get(cookieConsentMarketingName) ||
    (isCookiesMarketingChecked && isFormSubmitted);

  return (
    <div>
      {Cookies.get(cookieConsentName) || !isOpen ? (
        <>
          <Helmet>
            {/*helmet does not support react fragment*/}
            {canRenderAnalyticsCookies && (
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-EJCSD62DF8"
              ></script>
            )}
            {canRenderAnalyticsCookies && (
              <script>
                {`window.dataLayer = window.dataLayer || []; function gtag()
                  {dataLayer.push(arguments)}
                  gtag('js', new Date()); gtag('config', 'G-EJCSD62DF8');`}
              </script>
            )}
          </Helmet>
        </>
      ) : (
        <>
          <Root>
            <div className="container py-4 text-center text-sm-left d-flex flex-col flex-md-row">
              <div className="row">
                <div className="col-lg-8">
                  <Paragraph className="py-0 my-0">
                    {t("cookieConsent.description")}{" "}
                    <a href={settings.privacyPolicy} target="_blank">
                      {t("cookieConsent.cookieInformation")}
                    </a>
                    .{" "}
                    <a href="#" onClick={handleModalOpen}>
                      {t("cookieConsent.cookieSettings")}
                    </a>
                  </Paragraph>
                </div>
                <div className="col-lg-4 mt-3 mt-lg-0 justify-content-center justify-content-lg-start d-flex align-items-center">
                  <Button
                    className="mr-3"
                    variant={ButtonVariant.ternary}
                    handleClick={handleModalOpen}
                  >
                    {t("cookieConsent.btn.cookieSettings")}
                  </Button>
                  <Button
                    variant={ButtonVariant.primary}
                    handleClick={() => handleAllowCookies(true)}
                  >
                    {t("cookieConsent.btn.allowAllCookies")}
                  </Button>
                </div>
              </div>
            </div>
          </Root>
          {isModalOpen && (
            <>
              <div className="modal d-block" role="dialog">
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {t("cookieConsent.modal.title")}
                      </h5>
                      <button
                        onClick={() => setModalIsOpen(false)}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>{t("cookieConsent.modal.description")}</p>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="functionalCookiesSwitch"
                          disabled={true}
                          checked={true}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="functionalCookiesSwitch"
                        >
                          {t("cookieConsent.modal.functionalCookies.title")}
                        </label>
                      </div>
                      <Paragraph>
                        {t("cookieConsent.modal.functionalCookies.description")}
                      </Paragraph>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="analyticCookiesSwitch"
                          checked={isCookiesAnalyticsChecked}
                          onChange={(e) =>
                            setCookiesAnalyticsIsChecked(e.target.checked)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="analyticCookiesSwitch"
                        >
                          {t("cookieConsent.modal.analyticCookies.title")}
                        </label>
                      </div>
                      <Paragraph>
                        {t("cookieConsent.modal.analyticCookies.description")}
                      </Paragraph>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="marketingCookiesSwitch"
                          checked={isCookiesMarketingChecked}
                          onChange={(e) =>
                            setCookiesMarketingIsChecked(e.target.checked)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="marketingCookiesSwitch"
                        >
                          {t("cookieConsent.modal.marketingCookies.title")}
                        </label>
                      </div>
                      <Paragraph>
                        {t("cookieConsent.modal.marketingCookies.description")}
                      </Paragraph>
                    </div>
                    <div className="modal-footer">
                      <Button
                        variant={ButtonVariant.primary}
                        handleClick={() => handleAllowCookies()}
                      >
                        {t("cookieConsent.modal.allowSelection")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CookieConsent;
