const colors = {
  blueLightest: "#F3F7FE",
  blueLight: "#8ACDEF",
  blueRegular: "#1B52CB",
  blueDark: "#10317A",
  grey: "#535760",
  beige: "#FBF2D7",
  greenRegular: "#2AB45A",
  greenDark: "#1D7D3F",
  red: "#FF655E",
  white: "#FFFFFF",
  offWhite: "#F5F5F5",
};

const boxShadow = {
  regular: "0 15px 32px -7px rgba(0,0,0,0.3)",
  active:
    "0 15px 32px -7px rgba(0,0,0,0.3), 0 33px 32px -7px rgba(83,87,96,0.18)",
};

const fontSize = {
  heading1LargeFontSize: "56px",
  heading1FontSize: "32px",
  heading2FontSize: "32px",
  heading3FontSize: "24px",
  heading4FontSize: "16px",
  paragraphLarge: "18px",
  paragraphRegular: "16px",
  paragraphSmall: "14px",
};

export default {
  colors,
  boxShadow,
  fontSize,
};
