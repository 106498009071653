import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: "'Roboto',sans-serif",
  },
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&:before": {
            borderBottomWidth: "2px!important",
            borderColor: "#1B52CB!important",
          },
        },
        // input: {
        //   height: "15px",
        // },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          background: "white",
          paddingLeft: "8px",
          paddingTop: "13px",
          paddingBottom: "12px",
          color: "#535760",
        },
      },
    },
  },
});

export default theme;
