import "../styles/globals.css";
import type { AppProps } from "next/app";
import Footer from "../shared/components/footer/footer";
import Header from "../shared/components/header/header";
import { appWithTranslation } from "next-i18next";

import { globalStyles } from "../shared/styles";
import CookieConsent from "../shared/components/cookieConsent/cookieConsent";
import createEmotionCache from "../shared/createEmotionCache";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../shared/theme";
import React from "react";
import { CssBaseline } from "@mui/material";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { SnackbarProvider, useSnackbar } from "notistack";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: MyAppProps) {
  const [queryClient] = React.useState(() => new QueryClient());
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Hydrate state={pageProps.dehydratedState}>
        <CacheProvider value={emotionCache}>
          <SnackbarProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Header />
              {globalStyles}
              <Component {...pageProps} />
              <CookieConsent />
              <Footer />
            </ThemeProvider>
          </SnackbarProvider>
        </CacheProvider>
      </Hydrate>
    </QueryClientProvider>
  );
}

export default appWithTranslation(MyApp);
